@font-face {
    font-family: 'Satoshi';
    src: url('/public/font/Satoshi-Light.otf') format('opentype');
    src: url('/public/font/Satoshi-Regular.otf') format('opentype'); 
 
}
a:hover{
color: inherit;
}
body, html{
    font-family: 'Satoshi', sans-serif;
    font-size: 15px;
    width: 100%; 
  }
  h1, h2, h3, h4, h5{
    font-family: "Oleo Script", system-ui;
  }
  a{color:inherit;text-decoration: none;}
  .lazyload-wrapper {
    display: initial;
}
  .highlights {
    background: #28579E66;  
    color: #333;
    display: flex;
    padding: 0px 60px;
}
.highlightsicn img {
    width: 30px;
    margin-right: 30px;
}
.highlights p a {
    color: #333;
}
.highlights p {
    margin: 0;
    padding: 0; 
    display: inline-flex;
    font-size: 16px;
    line-height: 30px;
}
.highlights marquee {
    margin: 0;
    padding: 0;
    display: flex;
}
span.bounce1 img { width: 22px; }
.OnlineR a { color: #fff; }
a.parantlogo { padding: 2px 10px; }
.OnlineR { position: fixed; right: 0; z-index: 99; text-align: center; color: #fff; top: 40%; font-family: Open Sans, sans-serif; }
@keyframes bounce1 { 
  0% {
      background-color:#005c9f;
  }
  33% {
      background-color:   #093b60;

  }
  66% {
      background-color:#005c9f;
  }
  100% {
      background-color: #093b60;
  }
}
.bounce1 { border-radius: 25px 0px 0px 25px; color: #fff; font-size: 14px; font-weight: 700; text-align: center; animation: bounce1 1s infinite; padding: 10px 12px; }

.popup {
  padding-right: 0 !important;
}
.popup .carousel-control-prev-icon { background: url('https://webapi.entab.info/api/image/STFSR/public/Images/lefticn.png') no-repeat !important; }
.popup .carousel-control-next-icon { background: url('https://webapi.entab.info/api/image/STFSR/public/Images/righticn.png') no-repeat !important; }
.popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
.popup .carousel-control-next { right: -15px; }
.popup .modal-dialog { min-width:50%; transform: translateY(-100px); }
.carousel-item .modal-header { background: #002c4c; padding: 13px 0 0 }
.popup .modal-dialog img { width: 100%; object-fit: contain;  height: 800px;  background: #f1f1f1;  width: 100%; }
.carousel-item .modal-body h1 {padding-bottom: 0; text-align: center; font-size: 17px; color: #000; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
.carousel-item .modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
.carousel-item .modalcard .card-body { padding: 10px }
.carousel-item .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
.carousel-item .modalcard { border: none }
.popup .modal-content { padding: 0 0 10px }
.carousel-item button.btn-close:focus { box-shadow: none }
.carousel-item a.btn.btn-primary.btn-sm {background: #002c4c; border: #002c4c; margin: 0 10px; color: #fff!important ;}
.carousel-item a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #002c4c; }
.carousel-item button.btn-close {position: absolute;z-index: 999;background-color: #002c4c;right: 0;}
.carousel-item .btn-close { background: url('https://webapi.entab.info/api/image/STFSR/public/Images/closeicn.png') no-repeat; opacity: 1; padding: 10px; background-position: center; border-radius: 0; }
.modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 30px; }
 
.header-contact {
    display: flex;
}

.headercon img {
    width: 35px;
    margin-top: 15px;
    margin-right: 15px;

}

.headercon a, .headercon>div{
    align-items: center;
    display: flex;
}
.headercon h6 {
    font-size: 16px;
    font-weight: bold;
    color: #000;

}
.headercon {
    margin-right: 20px;
}
.navbar-expand-lg .dropdown-item.active {  
  background: #f9f5d5 !important;
} 
.navbar-dark .navbar-toggler-icon {outline: none;}
.navbar-dark .navbar-toggler-icon:focus {outline: none;}
.navbar-dark .navbar-nav .nav-link {color: #085dad;font-weight: bold; }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {color: #5f9019; } 
  
    .logo-sec-contact ul {margin: 0px;
      margin-bottom: 14px; }
      .logo-sec-contact ul li {
        color: #324759;
        display: block;
        text-align: center; }
        .logo-sec-contact ul li a {
          color: #324759;
          text-decoration: none; }
          .logo-sec-contact ul li a:hover {
            color: #000000; }
        .logo-sec-contact ul li i {
          color: #004d9b; }
      .logo-sec-contact ul:hover {
        color: #fff; }
  
  .menu-sec ul li i {
    color: #ffffff; 
    height: 31px;
    width: 31px;
    vertical-align: middle;
    border-radius: 100%;
    display: inline-block;
    padding: 0px;
    font-size: 16px;
    margin-right: 5px;
    }
  .menu-sec .navbar {
    margin: auto;
    width: 80%;
     padding: 0;
    float: right; }
    .menu-sec .navbar-collapse ul {
      text-align: center; }
 
  .logo {padding-top: 5px; padding-left: 30px;  width: 370px;
    margin-top: 10px;}

.pp-login {
    padding-right: 30px;
}
.menu-sec .navbar li a, .nav-link{
    color: #fff !important;
    font-weight: 100 !important;
    font-size: 14px;
    line-height: 25px;
    text-transform: uppercase;
    padding: 20px 0px;
    font-family: 'Satoshi';
    letter-spacing: 1.0px;
}
.navbar-dark .navbar-nav .nav-link.active {
  background: #ffffff29;
}
ul.navbar-nav {
    flex-direction: row; 
    align-items: center;
    justify-content: center;
} 
ul.navbar-nav li {
    margin: 0 10px;
}
.blinker {
    background: #000;
    padding: 6px 13px !important;
    border-radius: 5px;
    box-shadow: 0px 0px 1px 0px #ddd;
    animation: 2s blinker infinite;
    position: relative;
    z-index: 99;
}
.blinker::after, .arrow::after {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 50%;
  position: absolute;
    
}
.arrow img {
    width: 45px;
}
 .arrow{
     
    animation: 2s shade infinite;
     border-radius: 50%
 }
 .navbar-expand-lg .navbar-nav .dropdown-menu li a {
  color: #333 !important;
    text-align: left;
    padding: 5px 10px;
    border-bottom: 1px solid #c7852254;
} 
.navbar-expand-lg .navbar-nav .dropdown-menu {
    padding: 0;
    border-radius: 0;
    margin-top: 0px;
} 
.navbar-expand-lg .navbar-nav .dropdown-menu li {
    padding: 0;
    margin: 0;
} 
.navbar-expand-lg .navbar-nav .dropdown-menu li a:hover {
    background: #f9f5d5;   
} 
.navbar-expand-lg .navbar-nav .dropdown-menu li:last-child a {
    border-bottom: 0px solid #ccc;
}
@keyframes shade{
    0%{ 
    box-shadow: 0 0 0 0px #e7a138, 0 0 0 0px #e7a138d1;
    } 
    100%{ 
        box-shadow: 0 0 0 15px rgba(0,210,255,0),0 0 0 30px rgba(0,210,255,0);
    }
}
@keyframes blinker{
    0%{
        background: #e7a138;
    box-shadow: 0 0 0 0px #e7a138, 0 0 0 0px #e7a138d1;
    }
    50% { transform: scale(0.95); }
    100%{
        background: #ad6e11;
        box-shadow: 0 0 0 15px rgba(0,210,255,0),0 0 0 30px rgba(0,210,255,0);
    }
}
header {
    background: #ffffff;
    padding-top: 2px; 
}

.menu-sec {
    background: linear-gradient(180deg, #28579E 0%, #14305A 100%);

    padding: 0px 15px;
    color: #fff;
}
.navbar-dark .navbar-toggler-icon:focus {
    outline: none;
}
.navbar-dark .navbar-toggler-icon {
    outline: none;
}
 
.list_blk {
    position: relative;
    font-weight: 400;
    font-family: 'Oleo Script', system-ui;
} 
button.navbar-toggler {
    display: none;
}

  .gallery {
    padding: 60px 0;  
} 
.header-contact i {
    margin-right: 5px;
    color: #1c1b3b;
}

.header-contact p {
    margin-bottom: 2px;
    line-height: 17px;
    color: #666;
    font-size: 15px;
}
.header-contact p i {
    font-size: 19px;
}
.social-media ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin-bottom: 0;
    margin-top: 8px;
}

.social-media ul li {
    margin-right: 15px; 
    border-radius: 50%;
}

.social-media {
    margin-left: 30px;
}

.d-flex.space-between {
    align-items: center;
    justify-content: space-between;
}
.slider .demo {
    margin: auto; 
    width: 100%; 
    position: relative;
    box-shadow: 0px 0px 5px 0px #000;
    z-index: 9; 
}
.alumni-register span {
    display: block;
}
.alumni-register {
    position: fixed;
    right: 0%;
    transform: translate(-0%, -50%);
    top: 27%;
    z-index: 999;
    border-radius: 10px 0px 0 10px; 
    transition: 0.5s;
    cursor: pointer;
}
.AlumniRegistrationbox {
  background: #ffffff45;
  backdrop-filter: blur(5px);
  border-radius: 50%;
  width: max-content;
  margin: 0 auto;
}
.alumni-register img {
  padding: 10px;
  width: 70px;
}

.alumni-register { 
    padding: 4px 17px 4px 42px;
    border-right: 0;
}

.alumni-register p {
    margin-bottom: 0;
    font-family: 'Oleo Script', system-ui;
    font-size: 25px;
    text-align: center;
    margin-bottom: 0px; 
} 
.alumni-register:hover { 
    color: #fff;
}
.slider {
    width: 100%;
    position: relative;
    background-size: 100% 100%;
    overflow: hidden;
}

ul#content-slider img {
    width: 100%;
    max-height: 100%;
    display: block;
    position: relative;
}
ul.list-block {
    display: flex;
}
ul.list-block li {
    margin: 0 20px;
    position: relative;
    text-align: center;
    padding: 33px 10px;
    border-radius: 6px;
    box-shadow: 1px 5px 5px -1px #0000005c;
    font-size: 23px;
    width: 210px;
    background: url('https://webapi.entab.info/api/image/STFSR/public/Images/texture.png')  no-repeat bottom;
    border-bottom: 5px solid #FF4D00CC;
    overflow: hidden;
    background-size: 100% 100%;
}
ul.list-block li img {
    display: block;
    margin: auto;
    width: 70px;
    margin-bottom: 9px;
}
.mobileonline .fix-list {
    display: none;
}
.demo .item{
    position: relative;
    z-index: 9;
    overflow: hidden;
}
.about-img img {
    width: 56%;
    margin: 0px auto 40px;
    display: block;
    border-radius: 5px;
    position: relative; 
}

img.kgschool {
    position: absolute;
    right: 50px;
    bottom: -80px;
    width: 361px;
    z-index: 99;
    margin: 0; 
}
.titlehead {
    margin-bottom: 20px;
}
.about-img {
    position: relative;
}
.about-img:before {
    content: "";
    width: 60%;
    height: 100%;
    position: absolute;
    border:5px solid #28579E;  
    right: 108px;
    bottom: -50px;
}
.container-fluid.aboutdiv {
    padding: 0px 70px;
}
.about {
    margin: 120px 0;
    padding-right: 90px;
} 
 
.aboutdesc {
    display: -webkit-box;
    margin-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 12;
    overflow: hidden;
}
.titlehead span {
    color: #28579E;
    margin-bottom: 0;
    font-family: 'Oleo Script';
    font-size: 16px;
    line-height: 30px;
}
p span {
    color: #CC0F14;
    text-shadow: 2px 1px 2px #fff;
}
.about p {
    line-height: 34px;
    font-size: 18px;
    color: #555;
}
p {
    font-size: 16px;
}
ul.list-block {
    display: flex;
}

ul.list-block {
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: -80px;
    z-index: 99;
    position: relative;
}
ul.list-block li:before {
    position: absolute;
    content: ' ';
    bottom: 0;
    height: 0;
    width: 100%;
    left: 0;
    transition: all 0.5s ease-in;
}
ul.list-block li:nth-child(1):before{
background:#ff0000cc;

}
ul.list-block li:nth-child(2):before{
background:#CFCF00CC;

}
ul.list-block li:nth-child(3):before{
background:#26c1c9;

}
ul.list-block li:nth-child(4):before{
background:#0066FFCC;

}
ul.list-block li:nth-child(5):before{
background:#66E800CC;

}
ul.list-block li:nth-child(6):before{
background:#FF4D00CC;

}
ul.list-block li:hover:before {
    height: 100%;
}
ul.list-block li:hover {
    color: #fff;
}
ul.list-block li:nth-child(1) {
    border-bottom:5px solid #ff0000cc;
}
ul.list-block li:nth-child(2) {
    border-bottom: 5px solid #CFCF00CC; 

}
ul.list-block li:nth-child(3) {
    border-bottom: 5px solid #26c1c9;
}
ul.list-block li:nth-child(4) {
    border-bottom: 5px solid #0066FFCC;
 
}

ul.list-block li:nth-child(5) {
    border-bottom: 5px solid #66E800CC;
}
.message-content {
    padding: 27px 50px;
    background: #1c1b3b;
    height: 100%;
    box-shadow: 0px 0px 5px 0px #000;
    color: #fff;
}
.message:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #0000006e;
    position: absolute;
    top: 0;
    left: 0;
}
.message-content:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: -40px;
    top: 0;
    /* background: red; */
    border-top: 160px solid #0000;
    border-bottom: 160px solid #0000;
    border-right: 40px solid #1c1b3b;
}
.messageboxcontent p {
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 30px;
    margin-bottom: 0;
}
.messageboxcontent a  {
    display: block;
    text-align: center;
    margin:  15px auto 0px auto;
}
.messageboxcontent:after {
    top: 42%;
    position: absolute;
    content: '';
    border-bottom: 25px solid #ff000000;
    border-top: 25px solid #fff0;
    border-left: 50px solid #80808000;
    border-right: 50px solid #28579e;
    left: -100px;
}
.msgdcs.messageboxcontent:after {
    position: absolute;
    content: ' ';
    border-bottom: 25px solid #ff000000;
    border-top: 25px solid #fff0;
    border-left: 50px solid #80808000;
    border-right: 50px solid #097e3f00;
    left: -100px;
}
.msgdcs.messageboxcontent:before {
    top: 42%;
    position: absolute;
    content: '';
    border-bottom: 25px solid #da1b1b00;
    border-top: 25px solid #ba191900;
    border-left: 50px solid #28579e;
    border-right: 50px solid #d2ffe700;
    right: -100px;
    z-index: 1;
} 
.homemessage{
    background: url('https://webapi.entab.info/api/image/STFSR/public/Images/homemessage.jpg') no-repeat;
    padding: 100px 0px; 
}
.messagebox {
    display: flex;
    flex-wrap: wrap;
    background: #28579E;

}
.messageboximg img {
    width: 100%;
    height: 100%;
}
.messageboximg {
    width: 50%;
    position: relative;
    height: 325px;
    background: #dbdcd6;
}
.messageboxcontent {
    width: 50%;
    position: relative;
    padding: 20px;
}
button.viewall {
  background: transparent;
  transition: all 0.4s ease-in;
  border: 2px solid #fff;
  color: #fff;
  font-family: 'Satoshi';
  padding: 7px 10px;
  border-radius: 4px;
  letter-spacing: 2px;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 13px;
}
button.viewall:hover {
  box-shadow: inset 0px 0px 4px 6px #ffffff24, 0px 0px 4px 6px #ffffff24;
}
.messageboxdesc {
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    display: -webkit-box;
    margin-bottom: 0;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
 
}
.messageboxname {
    position: absolute;
    background: #00000063;
    padding: 10px 40px;
    border-radius: 5px;
    bottom: 30px;
    left: 30px;
    backdrop-filter: blur(5px);
}

.messageboxname h6 {color: #fff;font-size: 18px;font-family: 'Oleo Script';line-height: 30px;text-align: center;margin-bottom: 0;}

.messageboxname h6 span {
    display: block;
    font-family: 'Satoshi';
    font-size: 14px;
    line-height: 26px;
    opacity: 0.9;
    letter-spacing: 2px;
}
.messageboxcontent h5 {
    color: #FFFF00;
    text-align: center;
    font-size: 30px;
}
.shape-arrow::before {
    left: unset;
    right: -40px;
    z-index: 9;
    transform: rotatey(180deg);
}
.message-content p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    overflow: hidden;
}

.padding-0 {
    padding: 0;
    position: relative;
}

.message {
    padding: 90px 0; 
    background-size: 100% 100%;
    position: relative;
    background-attachment: fixed;
    }

.message-content h3 {
    color: #e3c351;
    margin-bottom: 18px;
    font-size: 38px;
}
    .arrow {
    position: absolute;
    transform: translate(-50%, -50%) rotate(180deg);
    right: -47px;
    top: 15%;
    z-index: 9;
}

.message-content h3 {
    font-size: 34px;
    color: #edd272;
}
.message img {
    height: 100%;
}
.galtitle {
    position: relative;
}
.gallery-blk {
    display: flex;
    flex-wrap: wrap;
    
    margin-bottom: 20px;
}

.gallery-blk .gallery-img {  
        border: 15px solid #ffffff;
        position: relative;
        margin: 10px;
        border-radius: 5px;
        width: 100%;
        box-shadow: 0px 0px 15px 0px #0000001a;
    } 

.gallery-blk .gallery-img img {
    width: 100%;
    height: 260px;
}
.gallbtn { 
    display: block;
    text-align: center;
    margin: 20px auto 20px auto;
} 
.gallery-blk .gallery-img .title p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 100;
  display: -webkit-box;
  margin-bottom: 0;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 20px;
  overflow: hidden;
}

.gallery-blk .gallery-img .title {
    position: absolute;
    background: #097E3FCC;
    width: 100%;
    z-index: 9;
    padding: 6px;
    text-align: center;
    bottom: 0px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    height: 37px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #fff;
}
.container-fluid.fulwidth {
    padding: 0 50px 0 50px;
}
.gallery-blk .gallery-img:hover .title {
    height: 100%;
    bottom: 0;
    font-size: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #fff;
}
.gallery-blk .gallery-img:hover .title p { 
  left: 50%; 
  position: relative;
  transform: translate(-50%, -50%);
  top: 50%;
}
.news-event { 
    padding: 100px 0;
    position: relative;
}
.news-event:before{
 position: absolute;
 width: 50%;
 height: 100%;
 content: ' ';
 top:0;
 left: 0;
 background: url('https://webapi.entab.info/api/image/STFSR/public/Images/news.jpg');
}
.news-event:after{
background: url('https://webapi.entab.info/api/image/STFSR/public/Images/birth.jpg') no-repeat top right;
position: absolute;
width: 50%;
height: 100%;
content: ' ';
top: 0;
right: 0;
background-size: cover;
z-index: -1;

}
.news {
    margin-bottom: 50px;
}

.news, .events {
    padding: 0 90px 0 40px;
}
.news-blk {
    background: #f5f5f5;
    padding: 0;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    width: 90%;
    border: 1px solid #28579e;
    margin-bottom: 10px;
}
.news-blk .date ,.event-blk .date{
    background: #fff;
    padding: 5px 30px;
    border-radius: 20px;
    color: #097E3F;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 0;
    left: 50%;
    box-shadow: 0px 0px 15px 0px #cc0f141f;
}

.news-blk .content {
    padding:  40px 20px 30px 20px;
    position: relative;
    width: 100%;
    text-align: center;
}
.news-blk .content h3 {
  font-size: 18px;
}

.news-blk .date h3  ,.event-blk .date h3 {
    text-align: center;
    font-size: 14px !important;
  line-height: 30px;
    margin-bottom: 0;
    color:#28579E !important;
}

.news-blk .content p, .event-blk .content p {
        display: -webkit-box;
    margin-bottom: 0;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.event-image {
    width: 100%;
    height: 140px;
    position: relative;
}

.event-image img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: contain;
    background: #f1f1f1;
}

.homeeventbottom {
    display: flex;
    justify-content: space-between;
   background: #28579E;

    padding: 5px;
    border-radius: 0px 0px 10px 10px;
    color: #fff;
}
.homeeventbottom p {
    margin-bottom: 0;
    font-size: 14px;
    font-family: "Oleo Script", system-ui;
    line-height: 26px;
}
.event-blk .content {
    background: #fff;
    text-align: center;
    padding: 10px;
}
.event-blk .content h3 ,.news-blk .content h3{
    font-size: 20px;
    line-height: 30px;
    color: #333; 
    display: -webkit-box; 
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.event-blk {
    width: 90%;
    flex-direction: column;
    display: flex !important; 
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 5px; 
} 
.event-blk .content p span {
    font-size: 76%;
}
.event-blk .content p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 30px;
    color: #666;
}
.acheiver-blk {
    margin-top: 25px;
    background: #f5f5f5;
    display: flex;
    width: 90%;
    border-radius: 7px;
    position: relative;
    border: 1px solid #E9770F;
    align-items: center;
    margin-left: 5px;
}
.acheiver-blk .acheiver-image img {
    width: 131px;
    border-radius: 10px;
    height: 125px; 
    object-fit: cover;
    padding: 5px;
}

.acheiver-image {
    float: left;
    margin-right: 5px;
}

.acheiver-blk .content {
    padding: 0px 0 3px;
    text-align: left;
    position: relative;
    width: 100%;
}
.acheiver-main .acheiver-blk .content h3 ,.birthday-main .acheiver-blk .content h3{
  font-size: 17px; 
  display: -webkit-box; 
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.acheiver-blk .percentage {
    font-size: 16px;
   background: #28579E;

    color: #fff;
    width: max-content;
    padding: 0px 10px;
    border-radius: 10px;
    line-height: 30px;
}

.acheiver-blk .content h3 {
    margin-bottom: 0; 
    font-size: 20px;
    line-height: 30px;
    color: #333;
}
.acheiver-blk img.balon-gif {
    width: 60px;
    position: absolute;
    right: 0;
    bottom:0px;
} 
.acheiver-main {
    margin-bottom: 58px;
}
.slick-prev {
    z-index: 9;
    left: 6px;
}
.slick-next {
    z-index: 9;
    left: 6px;
}
p.board {
    background: #fff;
    padding: 5px 15px;
    border-radius: 20px;
    color: #28579E; 
    position: absolute;
    top: -20px;
    font-family: 'Oleo Script';
    left: 0;
    box-shadow: 0px 0px 15px 0px #cc0f141f;
}
p.board i {
  padding-left: 18px;
}
.mainheading p {
    color:  #28579E; 
    margin-bottom: 0;
    font-family: 'Oleo Script';
    font-size: 16px;
    line-height: 30px;
}
.mainheading h2 {
    font-size: 35px;
    line-height: 45px;
    color: #333;
}
.mainheading {
    position: relative;
    text-align: center;
    margin-bottom: 15px;
}
.mainheading a i {
  position: absolute;
  right: 35px;
  transition: all 0.3s ease-in;
  top: 18px; 
  padding: 7px 13px;
  border-radius: 5px;
  color: #2c925d;
  font-size: 22px;
}
button.readmore:hover {
  box-shadow: inset 0px 0px 10px 1px #fff;
  filter: drop-shadow(0px 0px 2px #fff);
}
button.readmore {
  background: #0A8040;
  transition: all 0.4s ease-in;
  border: 0px;
  color: #fff;
  font-family: 'Satoshi';
  padding: 15px 25px;
  border-radius: 4px;
  letter-spacing: 2px;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 13px;
}
.mainheading a i:hover{
  background: #f1f1f1;
}
.ftr-logo { 
  margin-top: 10px;
}
.footer {
    width: 100%;
    padding: 0px 50px;
  }
  .app-cont p {
    font-size: 16px;
    line-height: 25px;
    text-align: center;
}
.quick-link {
    padding-left: 40px;
}
.cont-dtl {
    margin-left: -120px;
}
  .footer {
    margin-bottom: 0;
  }
  .quick-link h3, .cont-dtl h3 {
    font-size: 20px;
    color:  #28579E;
    ;
    line-height: 30px;
}
  
  .inr-event-img img{    height: 250px;
    object-fit: contain;}
    .title img {margin-left: 17px;}
    .upcoming img {
      width: 50px;
  }
    .title p {
        margin-bottom: 0;
        letter-spacing: 2px;
    }
    .change-title h3 {
      text-align: center;
      font-size: 24px;
      font-family: "Roboto Serif", serif;
      color: #1f4c87;
  }
  .title h3 {
    font-size: 31px;
    margin-top: 0; 
    -webkit-text-fill-color: #0000;  
    -webkit-background-clip: text;
    font-family: "Roboto Serif", serif;
    font-weight: 700;
  }
  .quick-link ul {
    display: flex;
    flex-direction: column;
    height: 200px;
    flex-wrap: wrap;
  }
  .cont-dtl img {
    width: auto !important;
  }
  .cont-dtl p {
    display: flex;
    font-size: 16px;
    line-height:26px;
    flex-direction: row;
    align-items: center;
  }
  .cont-dtl p span {
    font-weight: normal;
  }
  .cont-dtl p img {
    margin-right: 21px;
  }
  .ftr-logo .social-media img {
    width: auto;
}
.ftr-logo .social-media {
    display: flex;
    justify-content: center;
}
  .quick-link ul li {
    width: 50%;
  }
  footer { 
    background-size: 100% 100%;
    padding-top: 10px; 
    background: url('https://webapi.entab.info/api/image/STFSR/public/Images/footerbg.jpg') no-repeat;
    background-size: cover;
  }
  
  .footer>div img {
    width: 330px;
  }
  
  .footer iframe {
    height: 200px;
    border: 5px solid #fff;
    box-shadow: 1px 2px 5px 0px #646464;
  }
  
  .app-cont {
    width: 70%;
  }
  
  .app-cont img {
    width: 120px !important;
    margin-right: 10px;
}
  
  .phone-img img {
    width: 100% !important;
  }
  
  .phone-img {
    width: 30%;
  }
  
  .quick-link ul {
    padding: 0 0px;
  }
  
  .ftr-logo img {
    text-align: center;
    margin: 0 auto 10px auto;
    display: block;
}
  .quick-link ul li:before {
    left: 0;
    content: "";
    width: 11px;
    height: 21px;
   background: url('https://webapi.entab.info/api/image/STFSR/public/Images/footer-icon.png');
   background-repeat: no-repeat;
    position: absolute;
    border-radius: 2px;
    top: 11px;
  }
  
  .quick-link ul li {
    padding-left: 21px;
    list-style: none;
    position: relative;
    padding: 3px 20px;
    font-size: 16px;
    margin-bottom: 0;
  }
  
  .footer .d-flex {
    align-items: flex-end; 
  }
  
  .footer .link {  
    color: #333;
    font-size: 17px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 8px;
    margin-top: 16px;
    text-align: center;
    max-width: 100%;
    background: linear-gradient(180deg, #FFF5A7 0%, #FFB545 100%);
  }
  .copyright {
    background:  linear-gradient(180deg, #28579E 0%, #14305A 100%);
}
  .copyright p {
    text-align: center;
    margin-bottom: 0;
    padding: 5px 0;
    font-size: 14px;
    color: #ffffffbd;
    font-weight: 100;
    line-height: 24px;
}
.app-cont div {
    display: flex;
}
.slick-next, .slick-prev{top:0}
.slick-next:before, .slick-prev:before { 
 
    color: transparent; 
}
.slick-next, .slick-prev {
    top: -50px;
}

.slick-next { 
    left: 40px;
}
.innerslide {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: linear-gradient(360deg, #1c1b3b8f 100%, #1c1b3b91 100%, #3333338c 100%),url('https://webapi.entab.info/api/image/STFSR/public/Images/innersld.jpg') no-repeat center center;
    background-size: cover;
    height: 255px;
    padding-bottom: 50px;
  }
  .innersec {
    min-height: 400px;
    padding: 60px 10px 80px 10px;
  }
  .innersec p {
    line-height: 34px;
    font-size: 16px;
    color: #4e4e4e;
    font-family: "Roboto Slab", serif;
  }
  .innersec h1 {
    font-size: 35px;
    position: relative;
    padding-left: 70px;
    background: -webkit-linear-gradient(#16894d, #b7dbc8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 45px;
    padding-bottom: 20px;
  }
  .innersec h5 {
    font-size: 20px;
    font-family: "Roboto Slab", serif;
    color: #0664a8;
    line-height: 30px;
  }
  .innersec h1:after {
    position: absolute;
    content:  ' ';
    width: 60px;
    height: 3px;
    background:-webkit-linear-gradient(#16894d, #b7dbc8);
    top: 20px;
    bottom: 0;
    left: 0;
  }
  
  .breadcrumb li:before {
    position: absolute;
    content: ' \F138 ';
    width: 100%;
    height: 100%;
    font-family: bootstrap-icons;
    left: -15px;
  }
  .breadcrumb li:first-child:before {
    content: "\f404";
    background: #0d8144;
    width: 30px;
    border-radius: 20px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -25px;
    top: -3px;
  }
  .breadcrumb li a {
    color: #f9f9f9;
    position: relative;
  }
  .breadcrumb li {
    padding: 0px 15px 0px 15px;
    color: #fff;
    position: relative;
    margin-left: 7px;
    font-size: 14px;
  }
  ul.breadcrumb {
    margin-bottom: 0;
  }
  .clearfix p.name span {
    display: block;
    font-weight: bold;
    color: #b29a1c;
  }
  
  .clearfix p.name {color: #333;font-weight: bold;}
  .msgimg{ 
    object-fit: contain;
    border: 3px double #ccc;
    padding: 5px;
    border-radius: 7px;
    margin-left: 20px;
  }
  .mainlist {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    }
    .mainlist li{
      padding-left: 29px;
      position: relative;
      list-style: none;
      font-size: 16px;
      margin: 5px 0px;
      line-height: 30px;
      font-family: 'Roboto Slab';
    
    }
    .mainlist  li:before {
      content: "";
      width: 15px;
      height: 15px;
      background: radial-gradient(#ffffff, #e7bb3e, #ffffff);
      position: absolute;
      border-radius: 50%;
      left: 0px;
      top: 8px;
    }
    .tcblock {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      } .tc {
        width: 49%;
        margin-bottom: 30px;
      } .tcblock iframe {
        width: 49%;
      } .tc label {
        color: #000;
        width: 100%;
        margin-bottom: 0;
      } .tc label p {
        margin-bottom: 0px;
      } .tc .form-group {
        margin-bottom: 20px;
      } .form-control:focus {
        box-shadow: none;
        border: 1px solid #ccc;
      } a.viewdetailsbtn {
        color: #fff;
        font-size: 14px;
      } .viewdetailsbtn {
        padding: 10px 15px;
        text-decoration: none;
        color: #fff;
        border-radius: 5px;
        margin-bottom: 20px;
        border: none;
        background:linear-gradient(131deg, #bf8f12, #1d557e);
        transition: all 0.2s ease-in;
      } .viewdetailsbtn:hover {
        background:linear-gradient(131deg, #bf8f12, #1d557e);
      }
      tr.table-head td {
        color: #333;
        background: #fcfae1 !important;
    }
    .table td, .table th { 
        border: 1px solid #c3c3c3;
        color: #333;
        font-family: sans-serif;
        font-size: 100%;
        padding: 10px;
        vertical-align: top;
    }
    .magnify-modal {
        position: fixed !important;
        z-index: 999999999 !important;
        padding-top: 10px !important;
        left: 0 !important;
        top: 0 !important;
        width: 100% !important;
        height: 100% !important;
        overflow: auto !important;
        background-color: rgba(0, 0, 0, 0.8509803922) !important
      }
      
      .magnify-header .magnify-toolbar {
        float: right;
        position: relative;
        z-index: 9
      }
      
      .magnify-stage {
        position: absolute;
        top: 40px;
        right: 10px;
        bottom: 40px;
        left: 10px;
        z-index: 1;
        background: #0000000a;
        overflow: hidden
      }
      
      .magnify-image {
        position: relative;
        display: inline-block;
        border: 5px solid #fff
      }
      
      .magnify-image.image-ready {
        max-width: 100%;
        max-height: 100%
      }
      
      .magnify-footer {
        position: absolute;
        bottom: 0;
        z-index: 2;
        width: 100%;
        height: 40px;
        color: #fff;
        text-align: center
      }
      
      .magnify-footer .magnify-toolbar {
        display: inline-block
      }
      
      .magnify-button {
        display: inline-block;
        width: 40px;
        height: 40px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0;
        padding: 10px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        border-width: 0;
        border-radius: 0;
        cursor: pointer;
        outline: none;
        background: #055a91;
      }
      
      .magnify-button:hover {
        color: #fff;
        background-color: #e00000
      }
      
      .magnify-button-close:hover {
        background-color: #e00000
      }
      
      .magnify-loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.3);
        color: #333
      }
      
      .magnify-loader::before {
        content: '';
        display: inline-block;
        position: relative;
        width: 36px;
        height: 36px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-width: 5px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
        border-radius: 100%;
        -webkit-animation: magnifyLoading 1s infinite linear;
        animation: magnifyLoading 1s infinite linear
      }
      
      .magnify-loader::after {
        content: '';
        display: inline-block;
        width: 0;
        height: 100%;
        vertical-align: middle;
        overflow: hidden
      }
      
      @-webkit-keyframes magnifyLoading {
        0% {
            -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
            transform: rotateZ(0deg) translate3d(0, 0, 0)
        }
      
        100% {
            -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
            transform: rotateZ(360deg) translate3d(0, 0, 0)
        }
      }
      
      @keyframes magnifyLoading {
        0% {
            -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
            transform: rotateZ(0deg) translate3d(0, 0, 0)
        }
      
        100% {
            -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
            transform: rotateZ(360deg) translate3d(0, 0, 0)
        }
      }
      .same-box img {
        margin-bottom: 20px;
        padding: 10px;
        box-shadow: 0px 1px 10px 2px #ccc;
    }
    .contactusbox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .contactusdesc {
        display: flex;
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        align-items: center;
    }
    
    .contactusicn i {
        color: #fff;
        font-size: 20px;
    }
    
    .contactusicn {
        padding-right: 16px;
    }
    .contactusdiv {
        width: calc(35% - 30px);
        padding: 40px 30px;
        border-radius: 20px;
        margin: 0px 5px 20px 0px;
    }
    .fstcontact {
        background: #c72c31;
    }
    
    .scdcontact {
        background: #3c9867;
    }
    
    .thrdcontact {
        background: #f6a440;
    }
    .thrdcontact .contactusdesc,.scdcontact .contactusdesc {
        flex-wrap: wrap;
    }
    
    .thrdcontact .contactusdesc>div ,.scdcontact  .contactusdesc>div{
        display: flex;
        margin-bottom: 5px;
        padding-right: 25px;
    }
    .scdcontact p {
        color: #fff;
        margin-bottom: 0;
    }
    .contactiframe iframe {
        border: 10px solid #fff;
        box-shadow: 0px 0px 10px 1px #ccc;
        padding: 10px;
        border-radius: 3px;
    }
    .contactusimg h4 {font-family: 'Inter';margin-top: 25px;margin-bottom: 20px;color: #fff;font-size: 20px;line-height: 30px;}
    .erp_sec .guideheading {
        text-align: left;
      }
      #myImg, #myImg1, #myImg2 {
        width: 100%;
        border: 5px solid rgba(158, 158, 158, 0.1);
        background: #eaeaea;
        cursor: pointer;
        transition: 0.3s;
      }
      .erp_sec img { 
        display: inline-block;
      }
      .erp_sec h4.imgheading {
        text-align: center;
      }
      .assistance {
        background: #eaeeff;
        padding: 20px;
        margin-top: 30px;
      }
      .erp_sec h4 {
        text-align: center;
        line-height: 40px;
        font-size: 17px;
        margin-bottom: 0;
        margin-top: 15px;
        font-weight: 700;
        font-family: 'Roboto',sans-serif;
      }
      
      .assistance {
        background:rgb(230 243 255);
        padding: 13px;
        border: solid 1px #ddd;
        margin-top: 20px;
      }
      .erpbox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
      }
      
      .erpboxdesc {
        width: 72%;
      }
      
      .erpboximg {
        width: 26%;
      }
      
      .erpboxdescpopup {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
      
      .erppopup {
        width: 30%;
        margin: 10px;
      }
      
      .imgheading a { 
        text-align: center;
        width: 100%;
        display: block;
        font-size: 20px;
        padding: 10px 0;
      }
      
      .erppopup .modal-body h5 {
        color: #000;
        text-align: center;
        padding-top: 20px;
      }
       
      .modal {
        background: #0000008a;
        z-index: 99999;
        padding-right: 0 !important;
      }
      h3.Subhead {
        color: #104677;
        font-size: 20px;
      }
      .newscountbox {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin-bottom: 40px;
      }
      
      .news-block {
        width: 100%;
        position: relative;
        padding: 20px 10px 15px;
        background-color: #ffffffcf;
      }
      
      .newsCount .attachment {
        position: absolute;
        right: 0;
        bottom: 0px;
        background: #005694;
        padding: 8px 10px;
        border-radius: 3px 10px 3px 10px;
      }
      
      .newsCount .attachment a {
        color: #fff;
      }
      
      .newsCount {
        width: calc(51% - 35px);
        position: relative;
        padding: 15px;
        display: flex;
        background-color: #f2f4f7;
        justify-content: center;
        margin: 10px 10px;
      }
      
      .newsCount:before {
        position: absolute;
        left: 0;
        top: 0%;
        height: 0;
        width: 100%;
        content: "";
        background: linear-gradient(to right, #ff4200 0%,rgba(69,74,180,1) 50%,#ffc600 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f14b59', endColorstr='#ffa200',GradientType=1 );
        transition: all 500ms ease;
      }
      .newsCount:hover:before{
      height: 100%;
        top: 0;
      }
      .news-head h4 {
        padding: 10px 20px;
        font-size: 15px;
        background: #eea201;
        width: max-content;
        border-radius: 5px;
        color: #fff;
      }
      .news-head h3 {
        padding-left: 0;
        font-size: 16px;
        position: static;
        background: transparent;
        color: #000;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        padding-bottom: 0;
        font-family: 'Poppins';
      }
      
      .news-head h3:after {
        position: static;
      }
      .row.years {
        margin-bottom: 30px;
      } 
      .row.tabs-dynamic {
        background: #f1f1f1;
        padding: 10px;
        margin: 0px 0px 40px 0px;
      }
      
      .row.tabs-dynamic p {
        margin-bottom: 0;
      }
      
      .row.tabs-dynamic select {
        width: 100%;
        border: 0px;
        background: transparent;
        padding: 8px 5px;
        border-bottom: 1px solid #b6b6b6;
      }
      
      .row.tabs-dynamic input {
        padding: 9px 5px;
        width: 100%;
        border: 0px;
        border-bottom: 1px solid #b6b6b6;
        background: transparent;
      }
      
      .count-val p {
        margin-bottom: 0px;
      }
      .row.tabs-dynamic p a {
        color: #333;
      }    
      .eventbox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background: #f1f1f1bd;
        box-shadow: 2px 0px 8px 0px #ccc;
        margin-bottom: 20px;
      }
      
      .eventboximg {
        width: 40%;
        margin-right: 5px;
        position: relative;
      }
      
      .eventboxdesc {
        width: 58%;
      }
      .eventpgh {
        height: 125px;
        overflow-y: scroll;
        padding-top: 13px;
      }
      
      .eventboximg img {
        border: 10px solid #f1f1f1bd;
        height: 165px;
        object-fit: contain;
        background: #fff;
        width: 100%;
      }
      .eventdate {
        display: flex;
        justify-content: space-between;
        padding: 8px 10px 8px 5px;
      }
      .eventpgh p {
        font-size: 15px;
        line-height: 30px;
      }
      .eventdate a {
        border-radius: 5px 3px 6px 0px;
        color: #fff;
        padding: 2px 5px;
      }
      .eventdate span {
        font-size: 13px;
        line-height: 23px;
        color: #1c1b3b;
      }
      .eventpgh h5 {
        color: #9e790c;
        line-height: 30px;
        font-size: 18px;
        font-family: "Inter", sans-serif;
      }
      .eventdate span i {
        color: #1c1b3b;
        position: relative;
        bottom: 3px;
      }
      .eventpgh::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
      }
      
      .eventpgh::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
        background-color: #F5F5F5;
      }
      .eventattch {
        background: #f49a00;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0px 5px 4px 5px;
        border-radius: 0px 0px 10px 10px;
        color: #fff;
      }
      .gallery-inner {
        background: #005593;
        margin-bottom: 30px;
        box-shadow: 7px 6px 12px -4px #ddd;
        /* position: relative; */
        overflow: hidden;
        transition: all 0.5s ease-in;
      }
      .gallery-blks a {
        color: inherit;
      }
      .gallery-blks img {
        width: auto;
        max-width: 100%;
        height: 90%;
        margin: auto;
        display: block;
        position: relative;
        box-shadow: 0px 0px 19px 0px #00000054;
        border: 7px solid #ffffff4f;
      }
      .gallery-title h3::after {
        position: static;
      }
      .gallery-inner:hover .gallery-content {
        bottom: 0;
      }
      .gallery-blks {
        height: 230px;
        object-fit: contain;
        position: relative;
        background: #ddd;
        overflow: hidden;
        transition: 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
      } 
      
      .gallery-title p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        line-height: 28px !important;
        font-size: 16px !important;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      
      .gallery-date {
        display: flex;
      }
      
        .gallery-date h6 {
            color: #fff;
            font-size: 16px;
            margin-bottom: 10px;
            line-height: 25px;
            background: #0a7f40;
            padding: 5px 10px;
            border-radius: 5px;
            font-family: 'Poppins', sans-serif;
            margin-top: 0;
        }
      
      .gall-num h5 {
        background: transparent;
        color: #000;
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 25px;
        padding: 5px 10px;
        border-radius: 5px;
        font-family: 'Poppins', sans-serif;
      }
       
      .gallery-content {
        padding: 20px 15px;
        position: relative;
        background: #fff;
        width: 96%;
        margin: -30px auto 10px auto;
      }
       
      .gallery-title h3 {
        font-size: 18px;
        color: #b78c0b;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        padding: 0px;
        margin-bottom: 15px;
        font-family: 'Poppins', sans-serif;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        background: transparent;
      }
      h4.gallery-title {
        color: #000000bf;
        font-size: 20px;
        line-height: 30px;
        font-family: "Inter", sans-serif;
      }
      .sub-gallerycount img {
        border: 3px double #ccc;
        background: #f1f1f1;
        height: 200px;
        width: 100%;
        object-fit: cover;
        margin-bottom: 20px;
        padding: 4px;
      }

 
.topperbox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 30px;
}
.topperdesc p.name {
  color: #333;
}
.topperdesc p.per {
  color: #3d9d6b;
}
.topperdiv {
  margin-top: 20px;
  margin-bottom: 60px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topperimages img {
  border-radius: 80px;
  width: 150px !important;
  height: 150px; 
  margin-bottom:10px;
  padding: 8px;
  box-shadow: 0px 4px 6px 0px #ccc;
}
.topperimages {
  position: relative;
}

.topperdesc p {
  text-align: center;
  color: #fff;
  font-weight: 400;
  margin-bottom: 4px;
}
.tooperpicicn {
  margin-top: -167px;
  width: 200px !important;
}
.topperdesc p span {
  display: block;
  color: #000;
  font-weight: 300;
}
.per {
  font-weight: 500;
}
.toppermaintitle {
  text-align: center;
}
.toopersec .owl-dots {
    position: absolute;
    display: flex;
    top: 50%;
    flex-direction: column;
}
.toopersec {
    position: relative;
}
.topperrib {
  display: block;
  margin: 10px auto 30px auto;
}
.topper10bx .owl-dots {
  right: 20px;
}
.chooseyear {
  width: max-content;
  float: right;
  margin-bottom: 30px; 
} 
.clr {
  clear: both;
}
#chooseddlYear option { color: black;}
.chooseyear  select{margin:0px;}
.chooseyear  select{width:100%;background: unset;border: unset;height:28px; margin-top:15px;border: unset;border-bottom: 1px solid grey;}

.video-gallery {
  padding: 20px;
  box-shadow: 0px 1px 4px 1px #03030330;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 3px;
}
.video-gallery a {
  display: flex;
  color: inherit;
  align-items: center;
}
.video-gallery a i {
 font-size: 40px;
 color: #ad0807;
 margin-right: 10px;
 display: flex;
}
.video-gallery a h3 {
  font-family: "Roboto Slab", serif;
  font-size: 15px;
  margin-bottom: 0;
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 0;
  padding-left: 0;
}
.video-gallery p {
  font-size: 12px;
  margin-bottom: 0;
}
.video-gallery a h3:after {
  position: static;
}
.video-gallery a img {
  width: 52px;
  margin-right: 10px;
}
 .cmimg {
  margin: 0 auto;
  display: block;
}
.borderbottom {
  border-bottom: 1px dashed #ccc;
  margin-top: 20px;
  margin-bottom: 20px;
}
.acheiver-main .acheiver-image img {
  margin-top: 10px;
  height: 117px;
}
@media (max-width: 1800px){
.app-cont img {
    width: 110px !important;
}
}
@media (max-width: 1600px){
    .app-cont img {
        width: 96px !important;
    }
    .acheiver-image, ul#birthday .acheiver-blk .acheiver-image {
        margin-right: 6px;
    }
    .acheiver-blk .content h3, .news-blk .content h3, .event-blk .content {
        font-size: 21px;
    }
    .container-fluid.conductbox {
        padding: 0px 100px;
    }
    ul.list-block li img {
        width: 70px;
    }
    ul.list-block li {
        margin: 0 11px;
        font-size: 20px;
        width: 180px;
    }
    img.kgschool {
        width: 280px;
        right: 10px;
    }
    .about-img:before {
        right: 68px;
    }
    .about-img img {
        width: 65%;
    }
 
.news, .events {
    padding: 0 90px 0 20px;
}
}
@media (max-width: 1400px){
    .container-fluid.aboutdiv {
        padding: 0px 80px;
    }
    .news-blk { 
        margin-top: 45px;
    }
  
    .quick-link ul li {
        width: 100%;
    }
    .topperdiv {
        width: calc(25% - 20px);
    }
   
    .menu-sec {
        padding: 0px 5px;
    }
    .event-blk .content {
        padding: 10px 14px 5px 14px;
    }
    .news-blk .date {
        padding:  7px 18px;
        margin-top: -10px;
    }
    .acheiver-blk .acheiver-image img {
        width: 100px;
        height: 100px;
    }
    .event-blk .content h3 {
        font-size: 21px;
    }
    .news, .events {
        padding:0 20px 0 20px;
    }
    ul.navbar-nav li.nav-item.dropdown {
      
        margin: 0 2px;
    }
.menu-sec .navbar li a {
    padding-right: 4px !important;
    padding-left: 4px !important;
     
}
 
}
@media (max-width: 1260px){
    .headercon h6 {
        font-size: 14px;
    }
    .headercon img {
        width: 35px;
    } 
    .header-contact p { 
        font-size: 13px;
    }   
    .pp-login {
        padding-right: 0;
        position: fixed;
        right: 0;
        top: 20%;
        z-index: 1;
    }
}

@media (max-width: 1200px){
  .cont-dtl {
    margin-left: 0;
}
    .menu-sec .navbar { 
        width: 100%;
    }
    .container-fluid.aboutdiv {
        padding: 0px 0px;
    }
    .app-cont img {
        width: 130px !important;
    }
    .phone-img img {
        width: 70% !important;
    }
    .topperdiv {
        width: calc(33% - 20px);
    } 
    .link_Button img {
        margin: 10px;
        width: 140px;
    }  
    .news, .events {
        padding: 0 0px 0 0px;
    } 
    .message-content {
        padding: 27px 26px;
    }
    .message-content:before{
        display:none;
    }
    .about {
        padding: 0 20px;
    }
    li.nav-item.fix-list {
        position: fixed;
        right: 0;
        transform: translate(5%, -50%);
        bottom: 57%;
        z-index: 99;
    }
    header .d-flex.space-between {
        justify-content: end;
    }
 
 
}
@media (max-width: 991px){
  .tc {
    width: 100%;
  }
  .tcblock iframe {
    width: 100%;
}
  .contactusdiv {
    width: 49%;
  }
  .contactusdiv {
    width: 100%;
}
    .phone-img img {
        width: 100% !important;
    }
    .app-cont img {
        margin-right: 7px;
        width: 100px !important;
    }
    .messageboxcontent h5 { 
        font-size: 25px;
    }
    .messageboxcontent p {
        font-size: 15px;
    }    
    .news-event:before { 
        width: 100%;
    }
    .menu-sec .navbar {
        margin: auto;
        width: 100%; 
        float: none; 
    }
    .social-media { 
        margin-bottom: 20px;
    }
    .logo {
        padding-top: 5px;
        padding-left: 30px;
        text-align: center;
        display: block;
        margin: 0 auto;
    }
    header .d-flex.space-between {
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
    }
    .topperdiv {
        width: calc(50% - 20px);
    }   
    li.nav-item {
        z-index: 999;
        position: relative; 
        width: 100%;
        text-align: left !important;
    }
    ul.navbar-nav li {
    margin: 0  0px;
}
    .menu-sec .navbar-collapse ul {
        text-align: left;
    }
    .menu-sec ul li i{
        text-align: center;
    }
    .link_Button img {
        width: auto;
    } 
    .gallery-blk .gallery-img:hover .title {
        font-size: 17px;
    }
    .gallery-blk .gallery-img .title{
          font-size: 17px;
    }
    ul.list-block {
        flex-wrap: wrap;
    }  
    .news-event { 
        padding:50px 0px;
    }
    ul.list-block li {
        margin: 0 11px 22px;
        width: calc(30% - 22px);
    }
    .arrow {
        display: none;
    }
    .about-img {
        margin-bottom: 100px;
    }
    header .fix-list {
        display: none;
    } 
    button.navbar-toggler:focus {
        outline: none;
        box-shadow: none;
    }
    ul.navbar-nav li a {
        margin: 5px 2px;
    }
    .menu-sec .navbar-collapse ul {
        flex-direction: column;
        align-items: flex-start;
    }
    button.navbar-toggler {
        display: block;
        margin-left: auto;
        padding: 0;
        font-size: 24px;
        padding-right: 0;
        margin-top: 0;
    }
    .pp-login img {
        width: 60px;
    }
.menu-sec {
    padding: 0px 2px;
}
}
@media (max-width: 767px){
    .msgdcs.messageboxcontent:before { 
        position: static;
     }
    .quick-link ul { 
        height: auto;
    }
    .cont-dtl {
        margin-left: 0;
    }
    .quick-link {
        padding-left: 0;
    }    
    .phone-img img {
        width: 50% !important;
    }
    .app-cont div {
        display: flex;
    }
    .messageboxname { 
        width: 90%;
    }
    .messageboxcontent:after { 
        position: static;
    }
    .homemessage { 
        background-attachment: fixed;
    }
    .messageboximg {
        width: 100%;
    }
    .messageboxcontent {
        width: 100%;
    }
    .msgdcs.messageboxcontent {
        order: 2;
    }
    .acheiver-blk ,.news-blk ,.event-blk{ 
        width: 98%;
    }
    .gallery-blk .gallery-img:hover .title {
        font-size: 15px;
    }
    .gallery-blk .gallery-img .title{
          font-size: 15px;
    }
    .popup .modal-dialog {
        min-width: 98%;
        width: 98%;
    }  
    ul.list-block {
        flex-wrap: wrap;
        margin-top: 0;
    }
    .shuffleDiv {
        margin-top: 20px;
    }
    ul.list-block {
        margin: 40px 0 0;
    }
}
@media (max-width: 600px){
    .news, .events {
        margin: 0px 0 30px;
    }
    .gallery-blk .gallery-img {
        width: calc(100% - 20px);
    }
    ul.list-block li {
        width: calc(50% - 22px);
        padding: 5px 0;
    }
    .message {
        padding: 15px 0;
        background: #fff;
    }
    .message:before {
        background: #fff;
    } 
}
@media (max-width: 567px){
  .footer { 
    padding: 0px 10px;
}
    .header-contact { 
        flex-wrap: wrap;
    }
.headercon {
    width: 47%;
}
}
@media (max-width: 420px){
.alumni-register p{
    font-size:16px;
}
.alumni-register img {
    width:42px;
}
}

